import { ChainId } from '@sushiswap/core-sdk'
import BigNumber from 'bignumber.js'
import config from 'config'

export declare type StakerAddressInterface = {
  multiFeeDistributionAddress: string
  masterChefAddress: string
  // protocolOwnedDEXLiquidityTreasuryAddress: string,
  aaveOracleAddress: string
  lendingPoolAddressesProviderAddress: string
  uiPoolDataProvider: string
  lendingPoolAddress: string
  wETHGatewayAddress: string
  chefIncentivesControllerAddress: string
  aaveProtocolDataProviderAddress: string
  uiDataCalculator: string

  exchangeName: string
  swapUrl: string
  addLiquidityUrl: string

  loopAddress: string
}

export declare type AddressMap = {
  [chainId: number]: StakerAddressInterface
}

export declare type LoopableAddressMap = {
  [chainId: number]: string[]
}

export const GET = (chainId?: ChainId): StakerAddressInterface => {
  if (!chainId) {
    return STAKE_ADDRESS[config.defaultChainId]
  }
  return STAKE_ADDRESS[chainId] ?? STAKE_ADDRESS[config.defaultChainId]
}

export const GET_LOOPABLE = (chainId?: ChainId): string[] => {
  if (!chainId) {
    return LOOPABLE_TOKEN_ADDRESS[config.defaultChainId]
  }
  return LOOPABLE_TOKEN_ADDRESS[chainId] ?? LOOPABLE_TOKEN_ADDRESS[config.defaultChainId]
}

const STAKE_ADDRESS: AddressMap = {
  [ChainId.BSC]: {
    multiFeeDistributionAddress: '0x1Fb62978490d96164a67c34FF2653374a93dC519', //xy
    masterChefAddress: '0x3Db2Fe21616bA306Aaa6412A60b2a40e6e2320a4', //xy
    // protocolOwnedDEXLiquidityTreasuryAddress: '0x9af5fa118D892379daC7A28EfA6c30F5E15871fB',
    aaveOracleAddress: '0x57872c27051719f3a09C3f5062739A96a71DFc7b', //xy

    lendingPoolAddressesProviderAddress: '0xC00Dc7704c4a2DC13EC767cD92fd14a8156378F2', //xy
    uiPoolDataProvider: '0xe2B8D95CB08717C2F6878b2A9760baEA687Ca114', //zy
    lendingPoolAddress: '0xc4224eCE06de07D4c4b4580440E6bc92f58A3C30', //xy
    wETHGatewayAddress: '0x50fcA4a2EF90feBf6f465550B4D528a76dCb5C84', //xy
    chefIncentivesControllerAddress: '0x4191E3260eE60758826B0BbE6d360cf10c01075E', //xy
    aaveProtocolDataProviderAddress: '0xD93A26280976567b4Ffcb9F996a77b4907513ee0', //xy
    uiDataCalculator: '0x425eBce49d471291ce66cCd9bf290277F46afae0', //zy

    exchangeName: 'PancakeSwap',
    swapUrl: 'https://pancakeswap.finance/swap?inputCurrency=BNB',
    addLiquidityUrl: 'https://pancakeswap.finance/add/BNB',

    loopAddress: '0xc087BAB6D6ff7998685c72f1F2Dfa0DE5Ff5820f', //xy
  },
}

const LOOPABLE_TOKEN_ADDRESS: LoopableAddressMap = {
  [ChainId.BSC]: [
    '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56', // BUSD
    '0x1AF3F329e8BE154074D8769D1FFa4eE058B1DBc3', // DAI
    '0x55d398326f99059fF775485246999027B3197955', // USDT
    '0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d', // USDC
  ],
}
