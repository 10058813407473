import { NATIVE } from '@sushiswap/core-sdk'
import Container from 'app/components/Container'
import { NAV_CLASS } from 'app/components/Header/styles'
import useMenu from 'app/components/Header/useMenu'
import Web3Network from 'app/components/Web3Network'
import Web3Status from 'app/components/Web3Status'
import useIsCoinbaseWallet from 'app/hooks/useIsCoinbaseWallet'
import { useActiveWeb3React } from 'app/services/web3'
import { useWalletModalToggle } from 'app/state/application/hooks'
import { useETHBalances } from 'app/state/wallet/hooks'
import Image from 'next/image'
import React, { FC } from 'react'
import ExternalLink from '../ExternalLink'

import { NavigationItem } from './NavigationItem'

const HEADER_HEIGHT = 64

const Desktop: FC = () => {
  const menu = useMenu()
  const { account, chainId, library } = useActiveWeb3React()
  const userEthBalance = useETHBalances(account ? [account] : [])?.[account ?? '']
  const isCoinbaseWallet = useIsCoinbaseWallet()
  const toggleWalletModal = useWalletModalToggle()

  return (
    <>
      <header className="fixed z-20 hidden w-full lg:block" style={{ height: HEADER_HEIGHT }}>
        <nav className={NAV_CLASS}>
          <Container maxWidth="7xl" className="mx-auto">
            <div className="flex items-center justify-between gap-4 px-6">
              <div className="flex gap-4">
                <div className="flex items-center w-24 mt-1 mr-2">
                  <Image src="/images/sculptor-white.png" alt="Sculptor logo" width="100px" height="60px" />
                </div>
                {menu.map((node) => {
                  return <NavigationItem node={node} key={node.key} />
                })}
              </div>
              <div className="flex items-center justify-end gap-2">
                {library && (library.provider.isMetaMask || isCoinbaseWallet) && (
                  <div className="hidden sm:inline-block">
                    <Web3Network />
                  </div>
                )}

                <div className="flex items-center w-auto text-sm font-bold border-2 rounded shadow cursor-pointer pointer-events-auto select-none border-dark-800 hover:border-dark-700 bg-dark-900 whitespace-nowrap">
                  {account && chainId && userEthBalance && (
                    <a onClick={toggleWalletModal} className="hidden px-3 text-high-emphesis text-bold md:block">
                      {/*@ts-ignore*/}
                      {userEthBalance?.toSignificant(4)} {NATIVE[chainId || 1].symbol}
                    </a>
                  )}
                  <Web3Status />
                </div>
                {/* <div className="hidden lg:flex">
                  <LanguageSwitch />
                </div> */}
              </div>
            </div>
          </Container>
        </nav>
        <div className="p-2 text-center text-white bg-red opacity-90">
          This site is legacy version and no longer under development. Please withdraw any funds you may have here. You
          can use latest version
          <ExternalLink className="ml-1 font-extrabold text-white underline" href={`https://sculptor.finance`}>
            here
          </ExternalLink>
        </div>
      </header>
      <div style={{ height: HEADER_HEIGHT, minHeight: HEADER_HEIGHT }} />
    </>
  )
}

export default Desktop
