import { ChainId } from '@sushiswap/core-sdk'
import config from 'config'

export declare type AddressMap = {
  [chainId: number]: string
}

export const GET_MULTICALL = (chainId?: ChainId): string => {
  if (!chainId) {
    return MULTICALL_ADDRESS[config.defaultChainId]
  }
  return MULTICALL_ADDRESS[chainId] ?? MULTICALL_ADDRESS[config.defaultChainId]
}

const MULTICALL_ADDRESS: AddressMap = {
  [ChainId.BSC]: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
  [ChainId.FANTOM]: '0x22D4cF72C45F8198CfbF4B568dBdB5A85e8DC0B5',
}
